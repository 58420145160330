import { Form, Input, InputNumber } from "antd";
import { Controller } from "react-hook-form";
import FormError from "./form-error";
import React, { useCallback } from "react";
import ReadonlyField from "./readonly-field";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { debounce } from "lodash";

interface IProps{
    label: string;
    name: string;
    control: any;
    required?: boolean;
    defaultValue?: string | number;
    error?: any;
    readonly?: boolean;
    contentReadonly?: React.ReactNode;
    disabled?: boolean;
}
export default function FormNumberInput({ label, name, control, required, defaultValue, error, readonly, contentReadonly, disabled = false }: IProps) {
    
    return (
        <ReadonlyField readonly={readonly} contentReadonly={contentReadonly} label={label} value={defaultValue}>
            <Form.Item label={`${label} ${required ? '*' : ''}`} required>
                <Controller
                    name={name}
                    defaultValue={defaultValue ? defaultValue : 1}
                    control={control}
                    disabled={disabled}
                    render={({ field: { onChange, value } }: any) => <InputNumber
                        disabled={disabled}
                        //addonBefore={<MinusOutlined onClick={() => onChange(value - 0.01)} size={20} />}
                        //addonAfter={<PlusOutlined onClick={() => onChange(value + 0.01) } size={20} />}
                        value={value}
                        onChange={(newValue) => {
                            if (newValue === null) {
                            onChange(null);
                            return;
                            }
                            let strValue = newValue.toString();

                            if (/^\d+\.\d{3,}$/.test(strValue)) {
                                newValue = Math.floor(newValue * 100) / 100;
                            }

                            onChange(newValue);
                        }}
                        formatter={(val) => {
                            if (val === undefined || val === null) return "";
        
                            let strVal = val.toString();
                            if (strVal.includes(".") && strVal.split(".")[1].length > 2) {
                                return (Math.floor(parseFloat(val) * 100) / 100).toString();
                            }

                            return strVal;
                        }}
                        parser={(val) => val ? parseFloat(val.replace(/[^0-9.]/g, "")) : 0}

                    />}
                />
                <FormError message={error?.message} />
            </Form.Item>
        </ReadonlyField>
    )
}